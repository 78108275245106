/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CanvasJSReact from '../assets/js/canvasjs.react'
import { Range, getTrackBackground } from 'react-range'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import GraphModal from './GraphModel'

import '../assets/sass/views/footer.scss'
import '../assets/sass/views/common.scss'

import discordicon from '../assets/images/discord-icon.svg'
import telegramicon from '../assets/images/telegram-icon.svg'
import meduimicon from '../assets/images/meduaim-icon.svg'
import twittericon from '../assets/images/twitter-icon.svg'
import facebookicon from '../assets/images/facebook-icon.svg'
import whiteballimg from '../assets/images/white-ball-img.png'
import previcon from '../assets/images/arrow-left.png'
import nexticon from '../assets/images/arrow-right.png'
import searchicon from '../assets/images/search-icon.svg'

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const Footer = (props) => {
  const { t } = useTranslation('global')
  const [winningDate, setWinningDate] = useState(props?.currentPool?.length ? props.currentPool : [])
  const [scoreDate, setScoreDate] = useState(props?.scoreDate?.length ? props.scoreDate : [])
  const [currentSlide, setCurrentSlide] = useState(10)
  const [scoreSlide, setScoreSlide] = useState(10)
  const [slideChange, setSlideChange] = useState(false)
  const [secondSlide, setSecondSlide] = useState(false)

  const winningnumbersdateslider = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    initialSlide: winningDate?.length ? winningDate.length - 1 : 9,
    afterChange: () =>
      setSlideChange(false)
  }
  const winningnumbersslider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    swipe: false,
    initialSlide: winningDate?.length ? (winningDate.length - 1) * 6 : 54,
    afterChange: () =>
      setSlideChange(false)
  }

  const winningScoreDateSlider = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    initialSlide: scoreDate?.length ? scoreDate.length - 1 : 9,
    afterChange: () =>
      setSecondSlide(false)
  }
  const winninScoreSlider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipe: false,
    initialSlide: scoreDate?.length ? (scoreDate.length - 1) * 6 : 54,
    afterChange: () =>
      setSecondSlide(false)
  }

  const yLabels = [t('component.footer.graph.never'), t('component.footer.graph.scarcely'), t('component.footer.graph.rare'), t('component.footer.graph.medium'), t('component.footer.graph.often')]
  const options = {
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#242424',
    colorSet: '#49B9FF',
    color: '#fff',
    markerType: 'circle',
    axisX: {
      title: '',
      gridThickness: 0,
      labelFontColor: 'white',
      minimum: screen.width < 992 ? 1 : 1.5,
      maximum: 42.3,
      viewportMinimum: 1.5,
      labelFontSize: screen.width < 992 ? 8 : 14,
      interval: screen.width < 992 ? 1 : 2
    }
  }
  const [data, setData] = useState([])
  const [flag, setFlag] = useState(true)
  const [modal, setModal] = useState(false)
  const [values, setValues] = useState([50])
  const [rangeYaxis, setRangeYaxis] = useState(0)
  const toggle = () => {
    return setModal(!modal)
  }

  useEffect(() => {
    if (props?.currentPool) {
      const winningArr = props?.currentPool
      winningArr.forEach((date) => {
        const newDate = date.date.split('.')
        date.newDate = newDate[1] + '/' + newDate[0] + '/' + newDate[2]
      })
      setWinningDate(winningArr)
    }
    if (props?.scoreDate) {
      const scoreArr = props?.scoreDate
      scoreArr.forEach((date) => {
        const newDate = date.date.split('.')
        date.newDate = newDate[1] + '/' + newDate[0] + '/' + newDate[2]
      })
      setScoreDate(scoreArr)
    }
    if (props?.graphData) {
      const graphData = props.graphData.slice(0, values[0])
      const finalArr = []
      flag && graphData?.forEach((element, index) => {
        element?.split(',').forEach((ele) => {
          const arrIndex = finalArr.findIndex((elem) => elem.x === ele)
          if (arrIndex !== -1) finalArr[arrIndex].y += 1
          else finalArr.push({ x: ele, y: 1 })
        })

        for (let index = 1; index < 43; index++) if (finalArr.findIndex((elem) => elem.x === index.toString()) < 0) finalArr.push({ x: index.toString(), y: 0 })

        setData([
          ...finalArr.sort((a, b) => a.x - b.x)
        ])
        if (index === graphData.length - 1) setFlag(false)
      })
      if (finalArr.length) {
        const yArr = finalArr
        yArr.sort((a, b) => {
          return a.y - b.y
        })
        const addNumber = (yArr[0]?.y + yArr[yArr.length - 1]?.y) / 4
        setRangeYaxis(addNumber)
      }
    }
  })

  options.data = [
    {
      type: 'area',
      color: '#417cff',
      markerType: 'circle',
      xValueFormatString: t('component.footer.graph.numbers') + ' #,##0.##',
      yValueFormatString: t('component.footer.graph.frequency') + ' #,##0.##',
      dataPoints: data
    }
  ]
  options.axisY = {
    title: '',
    gridThickness: 1,
    gridColor: 'rgba(20, 121, 184, 0.4)',
    lineThickness: 1,
    labelFontSize: screen.width < 992 ? 11 : 14,
    labelFontColor: 'white',
    includeZero: true,
    minimum: 0,
    maximum: (rangeYaxis * 4) + 0.1,
    interval: rangeYaxis,
    labelFormatter: function (e) {
      const yCats = yLabels[Math.floor(e.value / rangeYaxis)]
      return yCats
    }
  }
  const numberSlider = useRef()
  const numberSliderdate = useRef()
  const gotoNext = () => {
    numberSlider.current.slickNext()
    numberSliderdate.current.slickNext()
    if (!slideChange) setCurrentSlide(currentSlide + 1)
    setSlideChange(true)
  }

  const gotoPrev = () => {
    numberSlider.current.slickPrev()
    numberSliderdate.current.slickPrev()
    if (!slideChange) setCurrentSlide(currentSlide - 1)
    setSlideChange(true)
  }

  const scoreSlider = useRef()
  const scoreSliderDate = useRef()
  const goNext = () => {
    scoreSlider.current.slickNext()
    scoreSliderDate.current.slickNext()
    if (!secondSlide) setScoreSlide(scoreSlide + 1)
    setSecondSlide(true)
  }

  const goPrev = () => {
    scoreSlider.current.slickPrev()
    scoreSliderDate.current.slickPrev()
    if (!secondSlide) setScoreSlide(scoreSlide - 1)
    setSecondSlide(true)
  }
  const onSliderValChange = (e) => {
    setValues(e.values)
    setFlag(true)
  }
  const containerProps = {
    height: '300px',
    width: '100%'
  }

  return (
    <>
      <div className='graph-container'><GraphModal modal={modal} toggle={toggle} graphData={data} rangeYaxis={rangeYaxis} selectedRange={values} /></div>
      <div>
        {props.currentPool && props.graphData
          ? <div
            className={props.isVisibleBall
              ? 'home-footer-sec visibleBall'
              : 'home-footer-sec'}
          >
            <div className="winning-number-sec">
              <div className='winning-number-top'>
                <div className='winning-number-inner-sec'>
                  <div className="wunning-number-header">
                    <h3>{t('component.footer.winningNum')}</h3>
                    <Slider
                      {...winningnumbersdateslider}
                      className="winningnumbersdateslider"
                      ref={numberSliderdate}
                    >
                      {winningDate?.sort((a, b) => new Date(a.newDate).getTime() - new Date(b.newDate).getTime())?.map((cp, i) => (
                        <div key={cp.date}>
                          <p key={cp.date}>{cp.date.replaceAll('.', '/')}</p>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="winning-number-slider">
                    <Slider
                      {...winningnumbersslider}
                      className="winningnumbersslider"
                      ref={numberSlider}
                    >
                      {winningDate?.sort((a, b) => new Date(a.newDate).getTime() - new Date(b.newDate).getTime())?.map((cp) => cp?.draw?.split(' ').map((m, i) => (
                        <div className="privous-number" key={m}>
                          <h3>
                            <span>{m}</span>
                          </h3>
                        </div>
                      ))
                      )}
                    </Slider>
                  </div>
                  <div className="prev-number-label">
                    <button className={currentSlide === 1 && !slideChange ? 'disabled' : ''} onClick={currentSlide === 1 && !slideChange ? null : () => gotoPrev()}>
                      <img src={previcon} />
                    </button>
                    <p>{t('component.footer.numPrevDraw')}</p>
                    <button className={currentSlide === 10 && !slideChange ? 'disabled' : ''} onClick={currentSlide === 10 && !slideChange ? null : () => gotoNext()}>
                      <img src={nexticon} />
                    </button>
                  </div>
                </div>
              </div>
              <div className='winning-number-bottom'>
                <div className='winning-number-inner-sec'>
                  <div className="wunning-number-header">
                    <h3>{t('component.footer.winningSc')}</h3>
                    <Slider
                      {...winningScoreDateSlider}
                      className="winningnumbersdateslider"
                      ref={scoreSliderDate}
                    >
                      {scoreDate?.sort((a, b) => new Date(a.newDate).getTime() - new Date(b.newDate).getTime())?.map((cp, i) => (
                        <div key={cp.date}>
                          <p key={cp.date}>{cp.date.replaceAll('.', '/')}</p>
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="winning-number-slider">
                    <Slider
                      {...winninScoreSlider}
                      className="winningnumbersslider"
                      ref={scoreSlider}
                    >
                      {scoreDate?.sort((a, b) => new Date(a.newDate).getTime() - new Date(b.newDate).getTime())?.map((cp) => cp?.wns?.map((m, i) => (
                        <div className="privous-number" key={m}>
                          <label>{t('component.footer.class')} {i + 1}</label>
                          <h3>
                            <span>{m}</span>
                          </h3>
                        </div>
                      ))
                      )}
                    </Slider>
                  </div>
                  <div className="prev-number-label">
                    <button className={scoreSlide === 1 && !secondSlide ? 'disabled' : ''} onClick={scoreSlide === 1 && !secondSlide ? null : () => goPrev()}>
                      <img src={previcon} />
                    </button>
                    <p>{t('component.footer.scPrevDraw')}</p>
                    <button className={scoreSlide === 10 && !secondSlide ? 'disabled' : ''} onClick={scoreSlide === 10 && !secondSlide ? null : () => goNext()}>
                      <img src={nexticon} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img src={whiteballimg} className="whiteballimg" />

            <div className="chart-section">
              <button onClick={toggle} className='search-btn'><img src={searchicon} /></button>
              <p>{t('component.footer.numFrequency')} {values[0]} {t('component.footer.draw')}</p>
              <div className="range-slider-sec statics-range" >
                <Range
                  values={values}
                  step={10}
                  min={10}
                  max={100}
                  onChange={(values) => onSliderValChange({ values })}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: '8px',
                        background: '#417cff',
                        borderRadius: '8px'
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: '5px',
                          width: '100%',
                          borderRadius: 'px',
                          background: getTrackBackground({
                            values,
                            colors: [],
                            min: 10,
                            max: 100
                          }),
                          alignSelf: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        {children}
                      </div>
                      <ul>
                        {Array.from(Array(10), (op, i) =>
                          <li key={(i + 1) * 10}>{(i + 1) * 10}</li>
                        )}
                      </ul>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        style: {
                          width: '4px',
                          height: '16px',
                          outline: 'none',
                          borderRadius: '9px',
                          background: '#fff',
                          padding: '0',
                          border: 'solid 1px #fff'
                        }
                      }}
                    >
                      <div
                        style={{
                          height: '16px',
                          width: '6px',
                          borderRadius: '10px',
                          backgroundColor: isDragged ? '#548BF4' : '#CCC'
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className='chart-inner-sec'>
                <CanvasJSChart containerProps={containerProps} options={options} />
              </div>
            </div>
          </div>
          : ''}
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <p>&copy; {t('component.footer.copyRight')}</p>
              </div>
              <div className="col-md-4">
                <ul className="social-icons">
                  <li>
                    <a href="https://t.me/noracleio" target="_blank" rel="noreferrer">
                      <img src={telegramicon} />
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/noracle" target="_blank" rel="noreferrer">
                      <img src={discordicon} />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Noracleio" target="_blank" rel="noreferrer">
                      <img src={twittericon} />
                    </a>
                  </li>
                  <li>
                    <a href="https://medium.com/@media.noracle" target="_blank" rel="noreferrer">
                      <img src={meduimicon} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/people/Noracle/100090735297322" target="_blank" rel="noreferrer">
                      <img src={facebookicon} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="footer-links">
                  <li>
                    <a href="#">{t('component.footer.conditions')}</a>
                  </li>
                  <li>
                    <a href="#">{t('component.footer.privacy')}</a>
                  </li>
                  <li>
                    <a href="#">{t('component.footer.terms')}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Footer.propTypes = {
  currentPool: PropTypes.array,
  scoreDate: PropTypes.array,
  isVisibleBall: PropTypes.bool
}

export default Footer
