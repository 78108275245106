import axios from 'axios'
import { SiweMessage } from 'siwe'
import { useDisconnect } from '@web3modal/ethers/react'
import cookies, { getUserToken } from '../../cookies'
import Axios from '../../axios'

const { disconnect } = useDisconnect()

const API_BASE_URL = process.env.REACT_APP_NODE_BACKEND_URL

const disconnectWallet = async () => {
  await disconnect()
  cookies.remove('sWalletAddress', { path: '/' })
  cookies.remove('userToken', { path: '/' })
}

const checkToken = () => {
  const token = getUserToken()
  if (!token) {
    disconnectWallet()
    return null
  }
  return token
}

export async function getNonce () {
  return axios.get(`${API_BASE_URL}/user/get/nonces`)
}

export async function verifySignature (data) {
  return axios.post(`${API_BASE_URL}/user/verify-signature`, data)
}

export const signInWithEthereum = async (nonce, provider) => {
  const domain = window.location.host
  const origin = window.location.origin
  const signer = await provider.getSigner()
  const chainId = (await provider.getNetwork()).chainId

  const message = new SiweMessage({
    domain,
    address: signer.address,
    statement: 'Sign in with BNB to the app.',
    uri: origin,
    version: '1',
    chainId,
    nonce
  })

  return {
    sSignature: await signer.signMessage(message.prepareMessage()),
    sMessage: message.prepareMessage(),
    chainId
  }
}

const postWithAuth = async (url, data) => {
  const token = checkToken()
  if (!token) return null

  return Axios.post(url, data, {
    headers: { Authorization: token }
  })
}

export async function getTicketPrice (data) {
  return postWithAuth(`${API_BASE_URL}/get/ticket-price`, data)
}

export async function addTransaction (data) {
  return postWithAuth(`${API_BASE_URL}/user/transaction/add`, data)
}

export async function addTicket (data) {
  return postWithAuth('/tickets/store', data)
}

export async function enterCompetition (data) {
  return postWithAuth('/competition/enter', data)
}

export async function getMyGameTickets (tab) {
  const token = checkToken()
  if (!token || tab !== '1') return null

  return Axios.get('/game/myGameTickets', {
    headers: { Authorization: token }
  })
}

export async function getTicketDetail (id) {
  const token = checkToken()
  if (!token || !id) return null

  return Axios.get(`/game/gameDetails/${id}`, {
    headers: { Authorization: token }
  })
}

export async function replayResp (data, checkStatus) {
  const token = checkToken()
  if (!token) return null

  return Axios.post('/game/gameDetails/replay', {
    rut: checkStatus?.reuseTicket,
    run: checkStatus?.reuseNori,
    goID: data?.goID,
    tchs: data?.tchs,
    ts: data?.ts?.length ? data.ts : [],
    norID: data?.norID
  }, {
    headers: { Authorization: token }
  })
}

export async function deleteResp (data) {
  const token = checkToken()
  if (!token) return null

  return Axios.post('/game/myTickets/delete', data?.map((d) => d?.id), {
    headers: { Authorization: token }
  })
}

export async function getCompTickets (tab) {
  const token = checkToken()
  if (!token || tab !== '2') return null

  return Axios.get('/game/myNoriCompetitions', {
    headers: { Authorization: token }
  })
}

export async function deleteCompResp (data) {
  const token = checkToken()
  if (!token) return null

  return Axios.post('/game/myCompetitions/delete', data?.map((d) => d?.id), {
    headers: { Authorization: token }
  })
}

export async function getResTickets (tab) {
  const token = checkToken()
  if (!token || tab !== '3') return null

  return Axios.get('/game/myGameResults', {
    headers: { Authorization: token }
  })
}

export async function getResTicketDetail (id) {
  const token = checkToken()
  if (!token || !id) return null

  return Axios.get(`/game/noriDetails/${id}`, {
    headers: { Authorization: token }
  })
}

export async function deleteResResp (data) {
  const token = checkToken()
  if (!token) return null

  return Axios.post('/game/myResults/delete', data?.map((d) => d?.id), {
    headers: { Authorization: token }
  })
}

export async function getClaimAllResp () {
  const token = checkToken()
  if (!token) return null

  return Axios.get('/game/gains/claimAll', {
    headers: { Authorization: token }
  })
}

export async function getAffiliateAutoGenerate () {
  const token = checkToken()
  if (!token) return null
  return Axios.get('/affifliate/code/auto', {
    headers: { Authorization: token }
  })
}

export async function checkAffiliateData () {
  const token = checkToken()
  if (!token) return null
  console.log('Token: ', token)
  return Axios.get('/affifliate/check', {
    headers: { Authorization: token }
  })
}

export async function saveCode (data) {
  const token = checkToken()
  if (!token) return null
  return Axios.post('/affifliate/code/store', data, {
    headers: { Authorization: token }
  })
}

export async function getReferrals () {
  const token = checkToken()
  if (!token) return null
  console.log('Token: ', token)
  return Axios.get('/affifliate/referrals', {
    headers: { Authorization: token }
  })
}

export async function getActivities () {
  const token = checkToken()
  if (!token) return null
  console.log('Token: ', token)
  return Axios.get('/affifliate/referrals/activities', {
    headers: { Authorization: token }
  })
}

export async function getRewards () {
  const token = checkToken()
  if (!token) return null
  console.log('Token: ', token)
  return Axios.get('/affifliate/referrals/rewards', {
    headers: { Authorization: token }
  })
}

export async function deleteReferrals (data) {
  const token = checkToken()
  if (!token) return null
  return Axios.post('/affifliate/referrals/delete', data, {
    headers: { Authorization: token }
  })
}

export async function deleteActivities (data) {
  const token = checkToken()
  if (!token) return null
  return Axios.post('/affifliate/referrals/activities/delete', data, {
    headers: { Authorization: token }
  })
}

export async function deleteRewards (data) {
  const token = checkToken()
  if (!token) return null
  return Axios.post('/affifliate/referrals/rewards/delete', data, {
    headers: { Authorization: token }
  })
}
