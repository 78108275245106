import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import { pFactor, noriEvaluation, sellNori } from '../../../query/nori/nori.query'
import NoriEvaluationModal from '../../../components/NoriEvaluationModal'
import BuyModal from '../../../components/buyModal'
import infoicon from '../../../assets/images/info-icon.png'
import readyimg from '../../../assets/images/ready-logo.png'
import completedicon from '../../../assets/images/completed-icon.svg'
import closeicon from '../../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

import ModalLimite from '../../../components/Modal'

const NinthComponent = (props) => {
  const { t } = useTranslation('global')
  const checkRef = useRef(null)
  const [isChecked, setIsChecked] = useState(props?.partNoriComp?.noriComp)
  const navigate = useNavigate()
  const [noriEvalData, setNoriEvalData] = useState({})
  const [modal, setModal] = useState(false)
  const [showBuy, setShowBuy] = useState(false)
  const [pFactorData, setPFactorData] = useState([])
  const [generated, setGenerated] = useState(props.tickets?.findIndex((t) => {
    return t.selectedNo.length === 0
  }))
  const togglemodal = () => {
    return setModal(!modal)
  }

  const [tooltipModal, setTooltipModal] = useState(false)
  const tooltipToggle = () => {
    return setTooltipModal(!tooltipModal)
  }

  const { refetch } = useQuery('sell-nori', () => sellNori(props.steps, props.npc.ncb, noriEvalData, props.noriPFactorData), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      const statusObj = props.noriStatus
      statusObj.status = 'active'
      props.setNoriStatus(statusObj)
    },
    onError: (error) => {
      if (error.response.status === 504) navigate('/error', { state: { errorMsg: t('views.nori.step9.error1') } })
      else navigate('/error', { state: { errorMsg: t('views.nori.step9.error') } })
    }
  })

  const { refetch: noriEvalRefetch } = useQuery('nori-evaluation', () => noriEvaluation(props.steps, props.npc.ncb), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      data.graphData = [{ x: 0, y: 0 }]
      data?.noriStats?.map((graph, i) => {
        data.graphData.push({ x: (i + 1) * 10, y: graph?.compliant })
        return data
      })
      setNoriEvalData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })
  const { refetch: pFactorRefetch } = useQuery('p-factor', () => pFactor(props.steps, props.npc.ncb), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: (data) => {
      setPFactorData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: 'Internal Server Error' } })
    }
  })

  const handleClick = () => {
    props.partNoriComp.generationComp = false
    const noriUpdate = props.partNoriComp
    if (Number(props.partNoriComp.noOfComb) + generated > 2000) props.limitToggleModal()
    else {
      if (Number(noriUpdate.noOfComb) === '' || Number(noriUpdate.noOfComb) <= 0) {
        noriUpdate.valError = 1
        props.setPartNoriComp({ ...props.partNoriComp, valError: 1 })
      } else {
        if (Number(noriUpdate.noOfComb) > props.npc?.ncb) {
          noriUpdate.valError = 2
          props.setPartNoriComp({ ...props.partNoriComp, valError: 2 })
        }
        if (noriUpdate.valError === 0) {
          props.refetch()
          setGenerated(generated + props.partNoriComp.noOfComb)
        }
      }
    }
    props.setPrevActiveStep(false)
  }
  function handleChange (no, e) {
    const finalObj = props.partNoriComp
    const regex = /^[0-9]+$/
    if (no === 1) {
      if (e.target.value === '') {
        finalObj.valError = 0
        finalObj.noOfComb = e.target.value
      } else {
        if (!regex.test(e.target.value)) {
          const position = e.target.selectionStart
          // eslint-disable-next-line no-useless-escape
          e.target.value = e.target.value.substring(0, position - 1) + e.target.value.substring(position + 1) // replace(/([a-zA-Z =&\/\\#, +()$~%.\-'":*?<>{}])/g, '')
          finalObj.valError = 0
        } else {
          finalObj.noOfComb = Number(e.target.value)
          finalObj.valError = 0
        }
        if (e.target.value > 1000) {
          e.target.value = e.target.value.replace(/([0-9])/g, '')
          setShow(true)
        }
      }
    }

    if (no === 2) {
      setIsChecked(prev => !prev)
      finalObj.noriComp = !isChecked
    }
    props.setPrevActiveStep(false)
    props.setPartNoriComp(finalObj)
  }
  const handleFinalClick = () => {
    refetch()
    props.setPrevActiveStep(false)
  }
  const handleCompClick = () => {
    setShowBuy(true)
    props.setPrevActiveStep(false)
  }
  const noriEvalHandleClick = () => {
    noriEvalRefetch()
    pFactorRefetch()
    togglemodal()
    props.setPrevActiveStep(false)
  }
  const noriHandleClick = () => {
    noriEvalRefetch()
    pFactorRefetch()
    togglemodal()
  }
  const [show, setShow] = useState(false)

  return (
    <>
    {showBuy
      ? <BuyModal
            showBuy={showBuy}
            setShowBuy={setShowBuy}

            nbrTicket={0}
            competition={'Yes'}

            nps={props.steps}
            nds={noriEvalData}
            pFactor={pFactorData}
            ncb={props.npc?.ncb}
          />
      : null
      }
      <ModalLimite title={t('global.attention')} content1={t('views.quickPick.limit')} validate={t('global.ok')} onClose={() => setShow(false)} show={show} />
      <div className='nori-last-step-box'>
        <div className='common-step-box'>
          <div className='text-center'>
            <img src={readyimg} className='readyimg' />
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='nori-form-grp combination-icon'>
                <label>{t('views.nori.step9.possibleCombinations')}<div className='tooltip-heading d-inline-block'>
                  <img src={infoicon} />
                  <span className='tooltiptext'>{t('views.nori.step9.total')}</span>
                </div>
                </label>
                <h4>{props.npc?.ncb}</h4>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='nori-form-grp combination-icon'>
                <label>{t('views.nori.step9.ratio1')}<div className='tooltip-heading d-inline-block'>
                  <img src={infoicon} />
                  <span className='tooltiptext'>{t('views.nori.step9.ratio2')}</span>
                </div>
                </label>
                <h4>{(props.npc?.rcb * 100).toFixed(6)} %</h4>
              </div>
            </div>
            {props.noriType === 1
              ? <><div className='col-md-12'>
                <div className='nori-form-grp nori-full-form'>
                  <div>
                    <div className='tooltip-heading'>
                      <img src={infoicon} />
                      <span className='tooltiptext'>{t('views.nori.step9.numbers1')}</span>
                    </div>
                    <p>{t('views.nori.step9.numbers2')}</p>
                  </div>
                  {
                    (Number(props.partNoriComp.noOfComb) === 0
                      ? <input type="text" defaultValue={1} onInput={(e) => handleChange(1, e)} />
                      : <input type="text" defaultValue={props.partNoriComp.noOfComb} onInput={(e) => handleChange(1, e)} />)
                  }
                </div>
              </div>
                <div><p className='error-msg show'>{props.partNoriComp.valError === 1 ? 'Please enter a valid positive number.' : props.partNoriComp.valError === 2 ? 'The desired number of combinations could not be totally generated according to your game configuration.' : ''}</p></div>
                <div className='col-md-12'>
                  <div className='nori-input-form-grp'>
                  {!props.partNoriComp.generationComp
                    ? (props.partNoriComp.noriComp === true
                        ? (<input type="checkbox" checked={isChecked} onChange={() => handleChange(2)} ref={checkRef} />)
                        : (<input type="checkbox" checked={isChecked} onChange={() => handleChange(2)} ref={checkRef} />))
                    : (<input type="checkbox" checked={isChecked} onChange={() => handleChange(2)} ref={checkRef} />)}
                    <label>{t('views.nori.step9.takePart')
                  }</label>
                  </div>
                  <div className='tooltip-heading d-inline-block combination-icon' style={{ 'margin-left': '10px' }}>
                    <img src={infoicon} />
                    <span className='tooltiptext'>{t('views.nori.step9.takePartInfo')}</span>
                  </div>
                </div></>
              : ''}
          </div>
          {props.noriType === 1
            ? <>
              <div className='generate-tickets-btns'>
                <a className='common-white-btn small-btn' onClick={noriHandleClick}><span className='tooltip-heading'>{t('views.nori.step9.evaluation')}<img src={infoicon} onClick={window.innerWidth < 1199 ? tooltipToggle : null} /> <span className='tooltiptext'>{t('views.nori.step9.infos')}</span></span></a>
                <a className='common-white-btn small-btn' onClick={handleClick}><span>{t('views.nori.step9.generate')}</span></a>
              </div>
              {
                props.partNoriComp.generationComp
                  ? props.partNoriComp.test === 1
                    ? <div className='generation-completed'><span>{props.partNoriComp.test} {t('views.nori.step9.completed')}<img src={completedicon} /></span></div>
                    : <div className='generation-completed'><span>{props.partNoriComp.test} {t('views.nori.step9.completeds')}<img src={completedicon} /></span></div>
                  : null
              }
            </>
            : props.noriType === 2
              ? props.noriStatus.status !== 'active'
                ? <div className='generate-tickets-btns'>
                  <a className='common-white-btn small-btn' onClick={noriEvalHandleClick}><span className='tooltip-heading'>{t('views.nori.step9.evaluation')} <img src={infoicon} onClick={window.innerWidth < 1199 ? tooltipToggle : null} /><span className='tooltiptext'>{t('views.nori.step9.infos')}</span></span></a>
                  <a className='common-blue-btn small-btn' onClick={handleFinalClick}><span>{t('views.nori.step9.sell')}</span></a>
                </div>
                : <><p className='note'>{t('views.nori.step9.sellThank')}</p><div className='generate-tickets-btns'>
                  <a className='common-white-btn small-btn' onClick={noriEvalHandleClick}><span className='tooltip-heading'>{t('views.nori.step9.evaluation')} <img src={infoicon} onClick={window.innerWidth < 1199 ? tooltipToggle : null} /><span className='tooltiptext'>{t('views.nori.step9.infos')}</span></span></a>
                </div></>
              : props.noriType === 3
                ? props.noriStatus.status !== 'active'
                  ? <div className='generate-tickets-btns'>
                    <a className='common-white-btn small-btn' onClick={noriEvalHandleClick}><span className='tooltip-heading'>{t('views.nori.step9.evaluation')} <img src={infoicon} onClick={window.innerWidth < 1199 ? tooltipToggle : null} /><span className='tooltiptext'>{t('views.nori.step9.infos')}</span></span></a>
                    <a className='common-blue-btn small-btn' onClick={handleCompClick}><span>{t('views.nori.step9.competition')}</span></a>
                  </div>
                  : <><p className='note'>{t('views.nori.step9.successThank')}</p><div className='generate-tickets-btns'>
                    <a className='common-white-btn small-btn disabled'><span className='tooltip-heading'>{t('views.nori.step9.evaluation')} <img src={infoicon} onClick={window.innerWidth < 1199 ? tooltipToggle : null} /><span className='tooltiptext'>{t('views.nori.step9.infos')}</span></span></a>
                    <a className='common-blue-btn small-btn disabled'><span>{t('views.nori.step9.competition')}</span></a>
                  </div></>
                : ''
          }
        </div>
      </div>
      <NoriEvaluationModal toggle={togglemodal} modal={modal} noriEvalData={noriEvalData} steps={props.steps} ncb={props.npc.ncb} noriPFactorData={props.noriPFactorData} setNoriPFactorData={props.setNoriPFactorData} />
      <Modal isOpen={tooltipModal} toggle={tooltipToggle} className='chart-modal'>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={tooltipToggle} className='common-close-btn'><img src={closeicon} /></button>
            <p>{t('views.nori.step9.infos')}</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

NinthComponent.propTypes = {
  steps: PropTypes.array,
  partNoriComp: PropTypes.object,
  setPartNoriComp: PropTypes.func,
  setPrevActiveStep: PropTypes.func,
  npc: PropTypes.object,
  limitToggleModal: PropTypes.func,
  refetch: PropTypes.func,
  setNoriStatus: PropTypes.func,
  noriType: PropTypes.number,
  noriStatus: PropTypes.object,
  noriEvalHandleClick: PropTypes.func,
  noriPFactorData: PropTypes.array,
  setNoriPFactorData: PropTypes.func,
  tickets: PropTypes.array
}

export default NinthComponent
