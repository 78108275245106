/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody } from 'reactstrap'
import infoicon from '../../assets/images/info-icon.png'
import closeicon from '../../assets/images/close-icon.svg'
import { useTranslation } from 'react-i18next'

const WinnerDeatilModal = (props) => {
  const { t } = useTranslation('global')
  return (
    <Modal toggle={props.toggle} className='settings-popup  winner-popup' isOpen={props.modal}>
      <ModalBody>
        <div className='common-modal-sec'>
          <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
          <div className='winning-details-sec winning-details-popup'>
            <div className='nori-details-top-sec'>
              <p>{t('views.myGame.winnerDetails.drawDate')}{props?.detailData?.drawDate ? `${new Date(props?.detailData?.drawDate).getUTCDate() < 10 ? '0' + new Date(props?.detailData?.drawDate).getUTCDate() : new Date(props?.detailData?.drawDate).getUTCDate()}/${(new Date(props?.detailData?.drawDate).getMonth() + 1) < 10 ? '0' + (new Date(props?.detailData?.drawDate).getMonth() + 1) : new Date(props?.detailData?.drawDate).getMonth() + 1}/${new Date(props?.detailData?.drawDate).getFullYear()}` : '02/02/2022'}</p>
            </div>
            <div className='nori-details-header-sec'>
              <div className='row'>
                <div className='col-xl-4 col-lg-4'>
                  <div className='nori-details-header'>
                    <p>{t('views.myGame.winnerDetails.numberOfNori')}<div className='tooltip-heading d-inline-block ml-5'><img src={infoicon} className='info-icon' /><span className="tooltiptext debordedLeft">{t('views.myGame.winnerDetails.tooltip1')}</span></div></p>
                    <h3>{props?.detailData?.myGameResultData?.ncb || 0}</h3>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4'>
                  <div className='nori-details-header'>
                    <p>{t('views.myGame.winnerDetails.ratio')}<div className='tooltip-heading d-inline-block ml-5'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.myGame.winnerDetails.tooltip2')}</span></div></p>
                    <h3>{(props?.detailData?.myGameResultData?.rcb * 100).toFixed(6) || 0} %</h3>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4'>
                  <div className='nori-details-header'>
                    <p>{t('views.myGame.winnerDetails.noriScore')}<div className='tooltip-heading d-inline-block ml-5'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.myGame.winnerDetails.tooltip3')}</span></div></p>
                    <h3>{props?.detailData?.myGameResultData?.nsc < 0 || props?.detailData?.myGameResultData?.nsc === null ? '-' : props?.detailData?.myGameResultData?.nsc?.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
              <div className='nori-details-main-sec'>
                <div className='text-center'>
                  <h4 className='common-title'>{t('views.myGame.winnerDetails.from')} “<span>{props?.detailData?.myGameResultData?.usr || '0100x52…7bx40'}</span>” </h4>
                </div>
                <ul>
                  {props?.detailData.myGameResultData?.cnf?.ops?.op1 ? <li>{t('views.myGame.winnerDetails.step1')}</li> : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op2 ? props?.detailData.myGameResultData?.cnf?.mns?.length ? <li>{t('views.myGame.winnerDetails.step2')}<ul className='nori-green-list'>{props?.detailData.myGameResultData?.cnf?.mns?.map((mandatory, i) => <li key={i}>{mandatory}</li>)}</ul></li> : '' : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op3 ? props?.detailData.myGameResultData?.cnf?.qns?.length ? <li>{t('views.myGame.winnerDetails.step3')}<ul className='nori-red-list'>{props?.detailData.myGameResultData?.cnf?.qns?.map((exclude, i) => <li key={i}>{exclude}</li>) || '-'}</ul></li> : '' : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op4 ? props?.detailData.myGameResultData?.cnf?.pd || props?.detailData.myGameResultData?.cnf?.pns?.length ? <li>{t('views.myGame.winnerDetails.step4')}<div><span>{t('views.myGame.winnerDetails.step41')}</span>{props?.detailData?.myGameResultData?.cnf?.pd || 49}%</div><div><span>{t('views.myGame.winnerDetails.step42')}</span><ul className='nori-yellow-list'>{props?.detailData.myGameResultData?.cnf?.pns?.map((priority, i) => <li key={i}>{priority}</li>) || '-'}</ul></div></li> : '' : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op5
                    ? props?.detailData.myGameResultData?.cnf?.ftr
                      ? <li>{t('views.myGame.winnerDetails.step5')}<ul>
                        <li><label>{t('views.myGame.winnerDetails.step51')}</label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.nbMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.nbMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step52')} </label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.evMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.evMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step53')}</label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.odMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.odMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step54')}</label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.evnbMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.evnbMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step55')}</label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.odnbMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.odnbMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step56')}</label> <span>{props?.detailData.myGameResultData?.cnf?.ftr?.pnMn || 0} - {props?.detailData.myGameResultData?.cnf?.ftr?.pnMx || 6}</span> </li>
                      </ul></li>
                      : ''
                    : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op6
                    ? props?.detailData.myGameResultData?.cnf?.adftr
                      ? <li>{t('views.myGame.winnerDetails.step6')}<ul>
                        <li><label>{t('views.myGame.winnerDetails.step61')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.avMn || 3} - {props?.detailData.myGameResultData?.cnf?.adftr?.avMx || 40}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step62')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.clMn || 5} - {props?.detailData.myGameResultData?.cnf?.adftr?.clMx || 41}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step63')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.spnbMn || 1} - {props?.detailData.myGameResultData?.cnf?.adftr?.spnbMx || 37}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step64')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.speqMn || 1} - {props?.detailData.myGameResultData?.cnf?.adftr?.speqMx || 5}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step65')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.spnqMn || 1} - {props?.detailData.myGameResultData?.cnf?.adftr?.spnqMx || 5}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step66')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.unMn || 1} - {props?.detailData.myGameResultData?.cnf?.adftr?.unMx || 6}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step67')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.acMn || 0} - {props?.detailData.myGameResultData?.cnf?.adftr?.acMx || 10}</span> </li>
                        <li><label>{t('views.myGame.winnerDetails.step68')}</label> <span>{props?.detailData.myGameResultData?.cnf?.adftr?.tolMn}</span> </li>
                      </ul></li>
                      : ''
                    : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op7
                    ? props?.detailData.myGameResultData?.cnf?.p1ns || props?.detailData.myGameResultData?.cnf?.p2nbs
                      ? <li>{t('views.myGame.winnerDetails.step7')}<ul className='nori-normal-list nori-partition-list'> <div><span>{t('views.myGame.winnerDetails.step71')}</span>{6 - Number(props?.detailData.myGameResultData?.cnf?.p1ns)}</div>
                        {props?.detailData.myGameResultData?.cnf?.p2nbs?.length ? <div><span>{t('views.myGame.winnerDetails.step72')}</span> {props?.detailData.myGameResultData?.cnf?.p2nbs.map((p1, i) => <li key={i}>{p1}</li>)}</div> : ''}
                      </ul></li>
                      : ''
                    : ''}
                  {props?.detailData.myGameResultData?.cnf?.ops?.op8
                    ? props?.detailData.myGameResultData?.cnf?.bcs?.length
                      ? <li>{t('views.myGame.winnerDetails.step8')}<ul className='nori-normal-list'>{props?.detailData.myGameResultData?.cnf?.bcs?.map((prio, i) =>
                        <><div><span>{t('views.myGame.winnerDetails.block')} {i + 1}:-</span>
                          <ul>{prio?.n?.map((subPrio, j) => <li key={j}>{subPrio}</li>)}</ul></div></>
                      )}</ul></li>
                      : ''
                    : ''}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

WinnerDeatilModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  detailData: PropTypes.object
}

export default WinnerDeatilModal
