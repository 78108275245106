import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import '../../assets/sass/views/Tutorial.scss'
import { useTranslation } from 'react-i18next'

const Help = () => {
  const { t } = useTranslation('global')
  const [loading, setLoading] = useState({ status: true })
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={4} />
      <div className='help'>
        <div className='presentation'>
          <h1>{t('views.help.title')}</h1>
          <p>{t('views.help.illustration')}</p>
        </div>
        <div className="accordeon">
          {[
            { id: 1, question: 'q1', answers: ['r1one', 'r1two', 'r1thre'] },
            { id: 2, question: 'q2', answer: 'r2' },
            { id: 3, question: 'q3', answer: 'r3' },
            { id: 4, question: 'q4', answer: 'r4' },
            { id: 5, question: 'q5', answers: ['r5one', 'r5two', 'r5thre', 'r5four'] },
            { id: 6, question: 'q6', answers: ['r6one', 'r6two'] },
            { id: 7, question: 'q7', answer: 'r7' },
            { id: 8, question: 'q8', answer: 'r8' },
            { id: 9, question: 'q9', answer: 'r9' },
            { id: 10, question: 'q10', answer: 'r10' },
            { id: 11, question: 'q11', answers: ['r11one', 'r11two'] },
            { id: 12, question: 'q12', answers: ['r12one', 'r12two', 'r12thre', 'r12four', 'r12five'] },
            { id: 13, question: 'q13', answer: 'r13' },
            { id: 14, question: 'q14', answers: ['r14one', 'r14two', 'r14thre', 'r14four'] },
            { id: 15, question: 'q15', answers: ['r15one', 'r15two', 'r15thre', 'r15four', 'r15five', 'r15six', 'r15seven', 'r15eight', 'r15nine', 'r15ten', 'r15eleven', 'r15twelve'] }
          ].map(({ id, question, answer, answers }) => (
            <div key={id}>
              <input type="radio" id={id} name="liste" />
              <label htmlFor={id}>
                <div>{t(`views.help.${question}`)}</div>
                <div className="cross"></div>
              </label>
              <div className="content">
                {Array.isArray(answers)
                  ? (answers.map((ans, index) => <p key={index}>{t(`views.help.${ans}`)}</p>))
                  : (
                    <p>{t(`views.help.${answer}`)}</p>
                    )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer isVisibleBall={false} />
    </>
  )
}

export default Help
