import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import closeicon from '../../assets/images/close-icon.svg'
import infoicon from '../../assets/images/info-icon.png'
import CanvasJSReact from '../../assets/js/canvasjs.react'
import '../../assets/sass/views/footer.scss'
import '../../assets/sass/views/common.scss'
import { useTranslation } from 'react-i18next'
const CanvasJSChart = CanvasJSReact.CanvasJSChart

const WinnerDetailModal = (props) => {
  const { t } = useTranslation('global')
  const [currentActiveTab, setCurrentActiveTab] = useState('1')
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
  }

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#1F1F1F',
    colorSet: '#49B9FF',
    color: '#fff',
    axisY: {
      title: '',
      interval: 1,
      labelFontColor: 'white',
      minimum: 0,
      maximum: 10.2
    },
    axisX: {
      title: '',
      prefix: '',
      interval: 10,
      minimum: -1,
      labelFontColor: 'white',
      maximum: 101
    },
    data: [{
      type: 'line',
      dataPoints: props?.noriEvalData?.graphData
    }]
  }
  const containerProps = {
    height: '300px',
    width: '100%'
  }

  const options1 = {
    theme: 'light2',
    animationEnabled: true,
    exportEnabled: true,
    backgroundColor: '#000',
    colorSet: '#49B9FF',
    color: '#fff',
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        const value = props?.subDetailData?.npf[e.entries[0].index]?.drawDate.replace('.', '/')
        if (value !== undefined) {
          const newValue = value.replace('.', ' ')
          const tooltipdata = 'Date <span class="blue-text">' + newValue + '</span><br/>' + 'P-Factor <span class="blue-text">' + props?.subDetailData?.npf[e.entries[0].index]?.pfactor.toFixed(6) + '</span>'
          return tooltipdata
        }
        return value
      }
    },
    axisY: {
      title: '',
      interval: 1,
      lineThickness: 1,
      lineColor: '#707070',
      labelFontColor: 'white',
      minimum: 0,
      maximum: 4.2
    },
    axisX: {
      title: '',
      prefix: '',
      ineThickness: 1,
      labelFontColor: 'white',
      labelFormatter: function (e) {
        const value = props?.subDetailData?.npf[e.value]?.drawDate.replace('.', '/')
        if (value !== undefined) {
          const newValue = value.replace('.', ' ')
          return newValue
        }
        return value
      }
    },
    data: [{
      type: 'area',
      dataPoints: props?.subDetailData?.npf?.map((p) => {
        return {
          y: p.pfactor
        }
      })
    }]
  }
  const containerProps1 = {
    height: '230px',
    width: '100%'
  }

  const handleTab = (no) => {
    if (no === 1) {
      toggle('1')
    } else if (no === 2) {
      toggle('2')
    }
  }
  return (
    <>
      <Modal toggle={props.toggle} className='preview-popup nori-evation-popup' isOpen={props.modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={props.toggle} className='common-close-btn'><img src={closeicon} /></button>
            <p className='played-options'>{t('views.winners.winnersDetails.playedOption')}
              <ul>
                {props?.subDetailData?.pos?.map((opt, i) =>
                  <li key={i}><span><span>{opt}</span></span></li>
                )}
              </ul>
            </p>
            <div className='preview-header-sec'>
              <ul>
                <li>
                  <p>{t('views.winners.winnersDetails.numberOfOption')}<div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.winners.winnersDetails.tooltip1')}</span></div></p>
                  <h3>{props?.subDetailData?.ncb || 0}</h3>
                </li>
                <li>
                  <p>{t('views.winners.winnersDetails.ratio')}<div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.winners.winnersDetails.tooltip2')}</span></div></p>
                  <h3>{props?.subDetailData?.rcb?.toFixed(6) || 0}</h3>
                </li>
                <li>
                  <p>{t('views.winners.winnersDetails.noriScore')} <div className='tooltip-heading'><img src={infoicon} className='info-icon' /><span className="tooltiptext">{t('views.winners.winnersDetails.tooltip2')}</span></div></p>
                  <h3>{props?.subDetailData?.nsc?.toFixed(4) || 0}</h3>
                </li>
              </ul>
            </div>
            <div className='previeew-main-sec'>
              <ul className='preview-header-list'>
                <li><span style={{ backgroundColor: '#4BE200' }}></span>{t('views.winners.winnersDetails.compliant')}</li>
                <li><span style={{ backgroundColor: '#FCA801' }}></span>{t('views.winners.winnersDetails.partialCompliant')}t</li>
                <li><span style={{ backgroundColor: '#FC002A' }}></span>{t('views.winners.winnersDetails.noCompliant')}</li>
              </ul>
              <div className='preview-details-main-sec'>
                <div className='preview-details-sec'>
                  <div className='preview-detail-left'>
                    <ul>
                      {props?.subDetailData?.nsd?.dateColors?.map((nori, i) =>
                        <li key={i}><span style={nori.color === 0 ? { backgroundColor: '#4BE200' } : nori.color === 1 ? { backgroundColor: '#FCA801' } : { backgroundColor: '#FC002A' }}></span>{nori.drawDate.replaceAll('.', '/')}</li>
                      )}
                    </ul>
                  </div>
                  <div className='preview-details-right'>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTab === '1'
                          })}
                          onClick={() => { handleTab(1) }}
                        >
                          {t('views.winners.winnersDetails.compliance')} <div className='p-relative d-inline-block tooltip-heading mb-0'><img src={infoicon} className='ml-5' /><span className="tooltiptext tooltip-modal-text">{t('views.winners.winnersDetails.complianceInfo')}</span></div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              currentActiveTab === '2'
                          })}
                          onClick={() => { handleTab(2) }}
                        >
                          {t('views.winners.winnersDetails.pFactor')} <div className='p-relative d-inline-block tooltip-heading mb-0'><img src={infoicon} className='ml-5' /><span className="tooltiptext tooltip-modal-text">{t('views.winners.winnersDetails.pFactorInfo')}</span></div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={currentActiveTab}>
                      <TabPane tabId="1">
                        <div className='tab-chart-sec'>
                          <CanvasJSChart options={options} containerProps={containerProps} />
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className='tab-chart-sec p-fact-chart-sec'>
                          <CanvasJSChart options={options1} containerProps={containerProps1} />
                          <span className='p-fact-horizon-title'>{t('views.winners.winnersDetails.drawDate')}</span>
                          <span className='p-fact-vertical-title'>{t('views.winners.winnersDetails.pFactors')}</span>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
WinnerDetailModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  subDetailData: PropTypes.object,
  noriEvalData: PropTypes.object
}

export default WinnerDetailModal
