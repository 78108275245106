import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loader'
import Footer from '../../components/footer'
import '../../assets/sass/views/Tutorial.scss'
import { useTranslation } from 'react-i18next'

const Videos = () => {
  const { t, i18n } = useTranslation('global')
  const testLang = i18n.language
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentVideo, setCurrentVideo] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(timer)
  }, [])

  const handlePreviewClick = (videoUrl) => {
    setCurrentVideo(videoUrl)
    setCurrentVideo(`${videoUrl}?autoplay=1`)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setCurrentVideo('')
  }

  const getThumbnail = (url) => `https://img.youtube.com/vi/${url.split('/').pop()}/hqdefault.jpg`

  return (
    <>
      {loading && <Loading addClass={'active'} />}
      <Header activeTab={2} />
      <div className="videos">
        {[
          { id: '1', videoUrl: testLang === 'fr' ? 'https://www.youtube.com/embed/S_MxaVV6iKw' : (testLang === 'de') ? 'https://www.youtube.com/embed/vyDOhtA3pe8' : 'https://www.youtube.com/embed/aCfLitxXVwM' },
          { id: '2', videoUrl: 'https://www.youtube.com/embed/-9-f6tvIT6Y' },
          { id: '3', videoUrl: 'https://www.youtube.com/embed/pmVYV9tKBto' },
          { id: '4', videoUrl: testLang === 'fr' ? 'https://www.youtube.com/embed/EYCdI2gJjhE' : 'https://www.youtube.com/embed/MewNNFBZ2yI' },
          { id: '5', videoUrl: testLang === 'fr' ? 'https://www.youtube.com/embed/J7iu0XK_R2I' : 'https://www.youtube.com/embed/ZMOH35_P5R0' }
        ].map(({ id, videoUrl }) => (
          <div key={id} className="item">
            <h1 className="video-title">{t(`views.video.t${id}`)}</h1>
            <div className="frame" onClick={() => handlePreviewClick(videoUrl)}>
                <img src={getThumbnail(videoUrl)} alt="Prévisualisation" className="preview-image" />
                <div className="play"></div>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="video-modal" onClick={handleCloseModal}>
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>X</button>
            <iframe
              src={currentVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
      )}

      <Footer isVisibleBall={false} />
    </>
  )
}

export default Videos
