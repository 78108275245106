import React, { useState, useEffect } from 'react'
import { addLeadingZeros } from '../../utils/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CurrentPool = (props) => {
  const { t } = useTranslation('global')
  const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    min: '00',
    sec: '00'
  })
  const calculateTimeRemaining = (nextDrawDate, currentTime) => {
    const remainingTime = new Date(nextDrawDate) - new Date(currentTime)
    if (remainingTime <= 0) {
      return { days: 0, hours: 0, min: 0, sec: 0 }
    }
    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000)

    return { days, hours, min: minutes, sec: seconds }
  }

  useEffect(() => {
    if (props?.poolData?.currentPool?.nextDrawDate && props?.poolData?.currentPool?.currentTime) {
      const { nextDrawDate, currentTime } = props.poolData.currentPool
      const initialTimeRemaining = calculateTimeRemaining(nextDrawDate, currentTime)
      setTimer(initialTimeRemaining)

      const timerInterval = setInterval(() => {
        const updatedTimeRemaining = calculateTimeRemaining(nextDrawDate, new Date())
        setTimer(updatedTimeRemaining)
      }, 1000)

      return () => clearInterval(timerInterval)
    }
  }, [props?.poolData?.currentPool])
  return (
    <div className='banner-common-box-sec right-common-box'>
      <div className='banner-common-box-padding'>
        <h4>{t('views.home.currentPool.title')}</h4>
        <h3>{props?.poolData?.currentPool?.totalValue} BNB</h3>
        <h3>({props?.poolData?.currentPool?.totalValueUSDT} $)</h3>
        <ul className='pool-list'>
          <li>{props?.poolData?.currentPool?.participatingAddresses}{t('views.home.currentPool.participAddr')}</li>
          <li>{props?.poolData?.currentPool?.ticketsBought}{t('views.home.currentPool.ticketBought')}</li>
          <li>{props?.poolData?.currentPool?.competingNoris}{t('views.home.currentPool.competingNori')}</li>
        </ul>
      </div>
      <div className='next-draw-timer-sec'>
        <div className='next-draw-sec'>
          <p>{t('views.home.currentPool.title2')}</p>
          <ul>
            <li>
              <h3>{addLeadingZeros(timer?.days)}</h3>
              <span>{t('views.home.currentPool.days')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.hours)}</h3>
              <span>{t('views.home.currentPool.hours')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.min)}</h3>
              <span>{t('views.home.currentPool.minuts')}</span>
            </li>
            <li>
              <h3>{addLeadingZeros(timer?.sec)}</h3>
              <span>{t('views.home.currentPool.seconds')}</span>
            </li>
          </ul>
        </div>
      </div>
    </div >
  )
}

CurrentPool.propTypes = {
  poolData: PropTypes.object
}

export default CurrentPool
