import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loader'
import '../../assets/sass/views/LiveDrawing.scss'
import livedraing from '../../assets/images/live-drawing-bg.gif'
import redballimg from '../../assets/images/red-ball-img.png'
import blueballimg from '../../assets/images/blue-ball-img.png'
import diceimg from '../../assets/images/dice-img.png'
import closeicon from '../../assets/images/close-icon.svg'
import starimg from '../../assets/images/star-img.svg'
import { Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getLiveDrawings } from '../../query/winners/winner.query'

const LiveDrawings = () => {
  const { t } = useTranslation('global')
  const { state } = useLocation()
  const [loading, setLoading] = useState({ status: true })
  const [modal, setModal] = useState(false)
  const [liveNumbers, setLiveNumbers] = useState({})
  const [ldnArray, setLdnArray] = useState([])
  const [ldsArray, setLdsArray] = useState([])
  const [lastDrawday, setLastDrawday] = useState('')
  const [nexttDrawday, setNextDrawday] = useState('')
  const [daysRemaining, setdaysRemaining] = useState(0)
  const [hoursRemaining, sethoursRemaining] = useState(0)
  const [minutesRemaining, setminutesRemaining] = useState(0)
  const [secondsRemaining, setsecondsRemaining] = useState(0)
  const ticketState = state?.ticketState
  const currentPoolData = state?.currentPoolData
  const togglemodal = () => {
    return setModal(!modal)
  }
  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false })
    }, 500)
  }, [])

  const { refetch } = useQuery({
    queryFn: () => getLiveDrawings(),
    onSuccess: (data) => {
      // setLiveNumbers(data?.data?.data?.aNumbers)
      setLiveNumbers(data.data)
    },
    onError: () => {
      console.log('error')
    }
  })

  const setDate = (theDate, number) => {
    const lDate = theDate
    const daysOfWeek = [t('views.liveDrawingDate.days.sunday'),
      t('views.liveDrawingDate.days.monday'),
      t('views.liveDrawingDate.days.tuesday'),
      t('views.liveDrawingDate.days.wednesday'),
      t('views.liveDrawingDate.days.thursday'),
      t('views.liveDrawingDate.days.friday'),
      t('views.liveDrawingDate.days.saturday')]
    const dayIndex = lDate.getDay()
    const dayName = daysOfWeek[dayIndex]
    const day = lDate.getDate()
    const month = lDate.getMonth() + 1 // Mois (0-11, donc ajouter 1)
    const year = lDate.getFullYear()
    const hours = lDate.getHours().toString().padStart(2, '0')
    const minutes = lDate.getMinutes().toString().padStart(2, '0')
    if (number === 1) {
      const formatted = `${day}/${month}/${year}`
      return dayName + ' ' + formatted
    } else {
      const formatted = `${day}/${month}/${year},   ${hours}:${minutes}`
      return dayName + ' ' + formatted
    }
  }
  const setLastDrawingUP = () => {
    try {
      const givenLastDate = new Date(liveNumbers.ldd)
      const givenNextDate = new Date(liveNumbers.ndd)
      setLastDrawday(setDate(givenLastDate, 1))
      setNextDrawday(setDate(givenNextDate, 0))

      const stringLiveNumbersLdn = JSON.parse(liveNumbers.ldn)// convert the ldn to array
      const stringLiveNumbersLds = JSON.parse(liveNumbers.lds)// convert the lds to array
      setLdnArray(stringLiveNumbersLdn)
      setLdsArray(stringLiveNumbersLds)
      const now = new Date(liveNumbers.cdt)
      const next = new Date(liveNumbers.ndd)
      const restTi = next - now
      setRestTime(restTi)
    } catch (error) {
      console.error('Erreur de parsing:', error)
    }
  }

  const [restTime, setRestTime] = useState(new Date(liveNumbers.ndd) - new Date(liveNumbers.cdt))
  useEffect(() => {
    const crestTime = restTime
    if (restTime <= 0) {
      setdaysRemaining(0)
      sethoursRemaining(0)
      setminutesRemaining(0)
      setsecondsRemaining(0)
    } else {
      const days = Math.floor(crestTime / (1000 * 60 * 60 * 24))
      const hours = Math.floor((crestTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((crestTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((crestTime % (1000 * 60)) / 1000)
      setdaysRemaining(days)
      sethoursRemaining(hours)
      setminutesRemaining(minutes)
      setsecondsRemaining(seconds)
    }
  }, [restTime])
  useEffect(() => {
    const givenLastDate = new Date(liveNumbers.ldd)
    const givenNextDate = new Date(liveNumbers.ndd)
    setLastDrawday(setDate(givenLastDate, 1))
    setNextDrawday(setDate(givenNextDate, 0))
  }, [t])
  useEffect(() => {
    const fetchNumbers = () => {
      if (liveNumbers != null && liveNumbers.length < 6) {
        refetch()
      } else {
        setLastDrawingUP()
      }
    }

    const checkTimeAndFetch = () => {
      const now = new Date()
      const utcHours = now.getUTCHours()
      const utcMinutes = now.getUTCMinutes()

      if (utcHours === 20 && utcMinutes === 30) {
        setLiveNumbers([])
        fetchNumbers()
      }
    }
    setLastDrawingUP()
    fetchNumbers()

    const intervalFetch = setInterval(() => {
      fetchNumbers()
    }, 2 * 60 * 1000) // 2 minutes in milliseconds
    const timer = setInterval(() => {
      setRestTime(prevTime => prevTime - 1000)
    }, 1000)
    const intervalTimeCheck = setInterval(() => {
      checkTimeAndFetch()
    }, 60 * 1000) // Check every minute

    return () => {
      clearInterval(intervalFetch)
      clearInterval(intervalTimeCheck)
      clearInterval(timer)
    }
  }, [liveNumbers, refetch])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <Header activeTab={1} tickets={ticketState} currentPoolData={currentPoolData} />
      <div className='live-drawing-sec'>
        <Sidebar sideActiveTab={6} tickets={ticketState} currentPoolData={currentPoolData} />
        <img src={blueballimg} className='blueballimg' />
        <div className='live-drawing-inner-sec'>
          <div className='live-drawing-header'>
            <div className='last-draw-header'>
              <h6>{t('views.liveDrawings.lastDraw')}, {lastDrawday}</h6>
              <ul>
                {ldsArray.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              {/* TODO: Design Fixed */}
              <div className='d-flex align-items-end justify-content-end'>
                <a className='common-blue-btn small-btn' href={`${process.env.REACT_APP_CHAINLINK_VRF}`} target='_blank' rel="noreferrer"><span>Chainlink VRF</span></a>
              </div>
            </div>
            <h1>{t('views.liveDrawings.title')}</h1>
            <ul className='live-drawing-list'>
              {liveNumbers.ldn?.length > 0 && ldnArray?.map((number, index) => {
                return <li key={index}><h3><span>{number}</span></h3></li>
              })}
            </ul>
          </div>
          <div className='live-drawing-footer'>
            <p>{t('views.liveDrawings.nextDraw')}{nexttDrawday}</p>
          </div>
          <div className='next-draw-footer'>
            <p>{t('views.liveDrawings.timeLeft')}</p>
            <img src={starimg} className='starimg' />
            <ul>
              <li>
                <h3>{daysRemaining}</h3>
                <span>{t('views.liveDrawings.days')}</span>
              </li>
              <li>
                <h3>{hoursRemaining}</h3>
                <span>{t('views.liveDrawings.hours')}</span>
              </li>
              <li>
                <h3>{minutesRemaining}</h3>
                <span>{t('views.liveDrawings.minuts')}</span>
              </li>
              <li>
                <h3>{secondsRemaining}</h3>
                <span>{t('views.liveDrawings.seconds')}</span>
              </li>
            </ul>
          </div>
          <img src={livedraing} className="live-dwaing-bg" />
          <img src={redballimg} className='redballimg' />
          <img src={diceimg} className='diceimg' />
        </div>
      </div>
      <Modal toggle={togglemodal} className='settings-popup result-popup' id='settings-popup' isOpen={modal}>
        <ModalBody>
          <div className='common-modal-sec'>
            <button onClick={togglemodal} className='common-close-btn'><img src={closeicon} /></button>
            <h4>My Result Details</h4>
            <div className='result-popup-header'>
              <div className='result-popup-header-data'>
                <h5>Draw Date: <strong>20/02/2022</strong></h5>
              </div>
              <div className='result-popup-header-data'>
                <h5>Win: <strong>5000 MATIC</strong></h5>
              </div>
            </div>
            <div className='common-winning-sec'>
              <h5>Winning tickets</h5>
              <ul className='winning-list'>
                <li>
                  <ul>
                    <li>1</li>
                    <li>5</li>
                    <li>28</li>
                    <li>1</li>
                    <li>5</li>
                    <li>28</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>1</li>
                    <li>5</li>
                    <li>28</li>
                    <li>1</li>
                    <li>5</li>
                    <li>28</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className='common-winning-sec'>
              <h5>Winning Noris</h5>
              <ul className='winning-noris-list'>
                <li>Winning class: &nbsp; 3 <a className='common-white-btn small-btn delete-ticket-btn'><span>View Details</span></a></li>
                <li>Winning class: &nbsp; 3 <a className='common-white-btn small-btn delete-ticket-btn'><span>View Details</span></a></li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Footer isVisibleBall={false} />
    </>
  )
}

export default LiveDrawings
