import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import {
  Modal, ModalBody,
  TabContent, TabPane, Nav,
  NavItem, NavLink
} from 'reactstrap'
import { getNonce, addTransaction, getMyGameTickets, getTicketDetail, deleteResp, getCompTickets, getResTickets, deleteResResp, getClaimAllResp, deleteCompResp } from '../../query/walletconnect/walletconnect.query'
import ConfirmationModal from './ConfirmationModal'
import MyGameDetailsModal from './MyGameDetailsModal'
import CompDetailsModal from './CompDetailsModal'
import ResultDetailsModal from './ResultDetailsModal'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import Loading from '../../components/Loader'
import blueballimg from '../../assets/images/blue-ball-img.png'
import DataTable from 'react-data-table-component'
import classnames from 'classnames'
import infoicon from '../../assets/images/info-img.svg'
import closeicon from '../../assets/images/close-icon.svg'
import redballimg from '../../assets/images/red-ball-img.png'
import bnb from '../../assets/images/bnb.svg'
import '../../assets/sass/views/MyGames.scss'
import { useTranslation } from 'react-i18next'

import { getSignature } from '../../query/winners/winner.query'
import { useDisconnect, useSwitchNetwork, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, ethers } from 'ethers'
import CONTRACT_DATA from '../../abi'
import { notify } from '../../utils/notify'
import cookies, { getUserToken } from '../../cookies'

const MyGames = () => {
  const { t } = useTranslation('global')
  const { open: openWallet } = useWeb3Modal()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [loading, setLoading] = useState({ status: true })
  const [mgId, setMgId] = useState('')
  const [compId, setCompId] = useState('')
  const [resId, setResId] = useState('')
  const [mgTicketData, setMgTicketData] = useState([])
  const [compTicketData, setCompTicketData] = useState([])
  const [resTicketData, setResTicketData] = useState([])
  const [detailData, setDetailData] = useState({})
  const [compDetailData, setCompDetailData] = useState({})
  const [resDetailData, setResDetailData] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [mgSelectedRows, setMgSelectedRows] = useState([])
  const [compSelectedRows, setCompSelectedRows] = useState([])
  const [resSelectedRows, setResSelectedRows] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState(state !== null && state?.tab !== '1' ? state?.tab : '1')
  const [popUp, setPopUp] = useState(0)
  const [popUpModal, setPopUpModal] = useState(false)
  const [gameModal, setGameModal] = useState(false)
  const [compModal, setCompModal] = useState(false)
  const [resModal, setResModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isClaim, setIsClaim] = useState(false)

  const [poolIds, setPoolIds] = useState([])
  const [amounts, setAmounts] = useState([])
  const [isDisabledClaim, setIsDisabledClaim] = useState(false)
  const connectDisconnectWallet = async () => {
    setIsSigned(false)
    if (isConnected) {
      cleanTables()
      await disconnectWallet()
    } else {
      await openWallet()
    }
  }

  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab)
    setLoading({ ...loading, status: true })
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }
  const popUpToggle = () => {
    return setPopUpModal(!popUpModal)
  }
  const gameToggle = () => {
    return setGameModal(!gameModal)
  }
  const compToggleToggle = () => {
    return setCompModal(!compModal)
  }
  const resToggle = () => {
    return setResModal(!resModal)
  }
  const deleteToggle = () => {
    return setDeleteModal(!deleteModal)
  }

  const handleMgChange = (mgSelectedRows) => {
    setMgSelectedRows(mgSelectedRows)
  }
  const handleCompChange = (compSelectedRows) => {
    setCompSelectedRows(compSelectedRows)
  }
  const handleResChange = (resSelectedRows) => {
    setResSelectedRows(resSelectedRows)
    setAmounts(resSelectedRows.selectedRows.map((d) => {
      return ethers.parseEther(d.winAmount.toString()).toString()
    }))
    setPoolIds(resSelectedRows.selectedRows.map((d) => d.poolId))
  }
  const cleanTables = () => {
    setMgTicketData([])
    setCompTicketData([])
    setResTicketData([])
  }

  const rowDisabledCriteria = (row) => {
    return row.clm === t('views.myGame.myGame.claimed') || row.winAmount === '0' || row.winAmount === 0
  }
  const handleClearRows = (no, flag) => {
    setPopUp(no)
    popUpToggle()
    if (flag) {
      if (no === 1) {
        if (currentActiveTab === '1') deleteRefetch()
        if (currentActiveTab === '2') deleteCompRefetch()
        if (currentActiveTab === '3') deleteResRefetch()
      } else if (no === 2) {
        claimAllRefetch()
      }
    }
  }
  // listining  to isSigned
  const [isSigned, setIsSigned] = useState(() => {
    const savedValue = localStorage.getItem('confirmissigned')
    return savedValue !== null ? JSON.parse(savedValue) : false
  })
  const loadVariableFromLocalStorage = () => {
    const value = localStorage.getItem('confirmissigned')
    setIsSigned(value === 'true')
  }
  useEffect(() => {
    loadVariableFromLocalStorage()
    const onStorageChange = (event) => {
      if (event.key === 'confirmissigned') {
        setIsSigned(event.newValue === 'true')
      }
    }
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])
  const handleLocalStorageChange = (newValue) => {
    setIsSigned(newValue)
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading({ ...loading, status: false })
    }, 500)
  }, [])

  const { refetch: refetchMyGame } = useQuery(['get-my-games-details', currentActiveTab], () => getMyGameTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setMgTicketData(data)
    },
    onError: (error) => {
      if (error.request.status === 401) console.log(error)
      else navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  useQuery(['get-details', mgId], () => getTicketDetail(mgId), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      setDetailData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  const { refetch: deleteRefetch } = useQuery('delete-refetch', () => deleteResp(mgSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = mgTicketData?.length ? [...mgTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.goID))
      setMgTicketData(filterData)
      setIsClaim(false)
      deleteToggle()
    }
  })

  const { refetch: refetchComp } = useQuery(['get-comp', currentActiveTab], () => getCompTickets(currentActiveTab), {
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      // const finalData = compTicketData?.length ? [...compTicketData] : []
      setCompTicketData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  const { refetch: deleteCompRefetch } = useQuery('delete-comp-refetch', () => deleteCompResp(compSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = compTicketData?.length ? [...compTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.norID))
      setCompTicketData(filterData)
      setIsClaim(false)
      deleteToggle()
    }
  })

  const { refetch: refetchResult } = useQuery(['get-result', currentActiveTab], () => getResTickets(currentActiveTab), {

    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      // const finalData = resTicketData?.length ? [...resTicketData] : []
      setResTicketData(data)
    },
    onError: () => {
      navigate('/error', { state: { errorMsg: t('views.myGame.myGame.error') } })
    }
  })

  const { refetch: deleteResRefetch } = useQuery('delete-res-refetch', () => deleteResResp(resSelectedRows.selectedRows), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.filter((d) => !data.includes(d.rid))
      setResTicketData(filterData)
      setIsClaim(false)
      deleteToggle()
    }
  })

  const { refetch: claimAllRefetch } = useQuery('claim-all-refetch', () => getClaimAllResp(), {
    enabled: false,
    select: (data) => {
      return data?.data
    },
    onSuccess: async (data) => {
      const finalData = resTicketData?.length ? [...resTicketData] : []
      const filterData = finalData.map((d) => {
        if (data.includes(d.rid)) d.clm = true
        return d
      })
      setResTicketData(filterData)
      setIsClaim(true)
      deleteToggle()
    }
  })

  const handleMgDetailClick = (id) => {
    setMgId(id)
    gameToggle()
  }
  const handleCompDetailClick = (id) => {
    const findId = compTicketData?.find((d) => d.norID === id)
    setCompDetailData(findId)
    setCompId(id)
    compToggleToggle()
  }
  const handleResDetailClick = (id) => {
    const findId = resTicketData?.find((d) => d.rid === id)
    setResDetailData(findId)
    setResId(id)
    resToggle()
  }

  const rows = mgTicketData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.goID
    mgArr.fd = mg?.fdt !== null ? `${new Date(mg.fdt).getUTCDate() < 10 ? '0' + new Date(mg.fdt).getUTCDate() : new Date(mg.fdt).getUTCDate()}/${(new Date(mg.fdt).getMonth() + 1) < 10 ? '0' + (new Date(mg.fdt).getMonth() + 1) : new Date(mg.fdt).getMonth() + 1}/${new Date(mg.fdt).getFullYear()}` : '-'
    mgArr.ld = mg?.ldt !== null ? `${new Date(mg.ldt).getUTCDate() < 10 ? '0' + new Date(mg.ldt).getUTCDate() : new Date(mg.ldt).getUTCDate()}/${(new Date(mg.ldt).getMonth() + 1) < 10 ? '0' + (new Date(mg.ldt).getMonth() + 1) : new Date(mg.ldt).getMonth() + 1}/${new Date(mg.ldt).getFullYear()}` : '-'
    mgArr.usages = mg.usg
    mgArr.details = <a onClick={() => handleMgDetailClick(mg.goID)} ><img src={infoicon} /></a>
    return mgArr
  })
  const columns = [
    {
      id: 1,
      name: t('views.myGame.myGame.firstDraw'),
      selector: (row) => row.fd,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.myGame.myGame.lastDraw'),
      selector: (row) => row.ld,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.myGame.myGame.usages'),
      selector: (row) => row.usages,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.myGame.myGame.details'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]

  const noriRows = compTicketData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.norID
    mgArr.dd = mg?.ddt !== null ? `${new Date(mg.ddt).getUTCDate() < 10 ? '0' + new Date(mg.ddt).getUTCDate() : new Date(mg.ddt).getUTCDate()}/${(new Date(mg.ddt).getMonth() + 1) < 10 ? '0' + (new Date(mg.ddt).getMonth() + 1) : new Date(mg.ddt).getMonth() + 1}/${new Date(mg.ddt).getFullYear()}` : '-'
    mgArr.score = mg?.nsc !== null ? mg?.nsc?.toFixed(2) : '--'
    mgArr.weight = mg?.nws !== null ? `${mg?.nws[4]} - ${mg?.nws[0]}` : '--'
    mgArr.details = <a onClick={() => handleCompDetailClick(mg.norID)} ><img src={infoicon} /></a>
    return mgArr
  })
  const noriColumns = [
    {
      id: 1,
      name: t('views.myGame.myGame.drawDate'),
      selector: (row) => row.dd,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.myGame.myGame.noriScore'),
      selector: (row) => row.score,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.myGame.myGame.noriWeight'),
      selector: (row) => row.weight,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.myGame.myGame.details'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]

  const gameResRows = resTicketData?.map((mg) => {
    const mgArr = {}
    mgArr.id = mg.rid
    mgArr.ddt = mg?.ddt !== null ? `${new Date(mg.ddt).getUTCDate() < 10 ? '0' + new Date(mg.ddt).getUTCDate() : new Date(mg.ddt).getUTCDate()}/${(new Date(mg.ddt).getMonth() + 1) < 10 ? '0' + (new Date(mg.ddt).getMonth() + 1) : new Date(mg.ddt).getMonth() + 1}/${new Date(mg.ddt).getFullYear()}` : '-'
    mgArr.clm = mg?.clm === true ? t('views.myGame.myGame.claimed') : <span className='disabled'>{t('views.myGame.myGame.notClaimed')}</span>
    mgArr.win = `${mg.win} BNB`
    mgArr.details = <a onClick={() => handleResDetailClick(mg.rid)} ><img src={infoicon} /></a>
    // Example:
    // mgArr.winAmount = '0.00008811' // BNB
    mgArr.winAmount = mg?.win?.toString()
    // Example:
    // mgArr.poolId = '51'
    mgArr.poolId = mg?.poolId?.toString()
    return mgArr
  })
  const gameResColumns = [
    {
      id: 1,
      name: t('views.myGame.myGame.drawDate'),
      selector: (row) => row.ddt,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 2,
      name: t('views.myGame.myGame.status'),
      selector: (row) => row.clm,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 3,
      name: t('views.myGame.myGame.winnings'),
      selector: (row) => row.win,
      sortable: false,
      reorder: false,
      center: true
    },
    {
      id: 4,
      name: t('views.myGame.myGame.details'),
      selector: (row) => row.details,
      sortable: false,
      reorder: false,
      center: true,
      width: '150px'
    }
  ]

  const paginationComponentOptions = {
    noRowsPerPage: true
  }
  const fetchData = async (page, no) => {
    const data = no === 1 ? [...mgTicketData] : no === 2 ? [...compTicketData] : [...resTicketData]
    const result = []
    for (let i = (9 * (page - 1)); i <= (page * 9) - 1; i++) {
      result.push(data[i])
    }
  }
  const handlePageChange = (page, no) => {
    fetchData(page, no)
  }

  const { disconnect } = useDisconnect()

  const disconnectWallet = async () => {
    await disconnect()
    cookies.remove('sWalletAddress', { path: '/' })
    cookies.remove('userToken', { path: '/' })
  }

  // Claim Earnings
  const { mutate: getSign } = useMutation({
    mutationFn: getSignature,
    onSuccess: (data) => {
      claimAll(data.data.signature)
      return data
    },
    onError: (error) => {
      setIsDisabledClaim(false)
      console.log(error)
      notify('error', error?.response?.data?.message || error?.message)
      if (error?.response?.status === 401) {
        disconnectWallet()
      }
    }
  })
  const { mutate: addTransactionMutation } = useMutation({
    mutationFn: addTransaction,
    onSuccess: (data) => {
      console.log('Claimed Transaction Initiated', data?.data?.message)
      notify('success', 'Claimed Transaction Initiated')
      setIsDisabledClaim(false)
    },
    onError: (error) => {
      console.log('Error claiming the transaction:', error)
      notify('error', error?.response?.data?.message || error?.message)
      if (error?.response?.status === 401) {
        disconnectWallet()
      }
      setIsDisabledClaim(false)
    }
  })

  // Get Address
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { switchNetwork } = useSwitchNetwork()
  const { walletProvider } = useWeb3ModalProvider()
  const { open: connectWallet } = useWeb3Modal()

  const claimAll = async (sign) => {
    console.log('poolIds', poolIds)
    console.log('amounts', amounts)
    try {
      // Contract call
      const ethersProvider = new BrowserProvider(walletProvider)
      const signer = await ethersProvider.getSigner()
      // The Contract object
      const NORA642Contract = new Contract(
        CONTRACT_DATA.NORA642Address,
        CONTRACT_DATA.NORA642ABI,
        signer
      )
      const gasEstimate = await NORA642Contract.claim.estimateGas(
        poolIds,
        amounts,
        sign, { from: address }
      )
      // call the participate functions
      const oTransaction = await NORA642Contract.claim(poolIds, amounts, sign, {
        from: address,
        gasLimit: gasEstimate
      })
      // add the transactionhash to the database
      addTransactionMutation({
        sTransactionHash: oTransaction.hash,
        sEventType: 'Claimed'
      })
    } catch (e) {
      console.log('handleClaimAll -> error', e)
      setIsDisabledClaim(false)
      const error = JSON.stringify(e)
      console.log(JSON.parse(error)?.reason)
      notify('error', JSON.parse(error)?.reason)
    }
  }

  const handleClaimAll = async () => {
    try {
      if (amounts.length === 0) {
        console.log('Please select the records to claim.')
        notify('error', 'Please select the records to claim.')
        return
      }
      if (!isConnected) {
        await connectWallet()
        return
      }
      setIsDisabledClaim(true)
      getSign({
        address,
        aAmounts: amounts,
        aPoolIds: poolIds
      })
    } catch (error) {
      console.log('handleClaimAll -> error', error)
      setIsDisabledClaim(false)
      notify('error', error?.message)
    }
  }

  const refetchTickets = () => {
    refetchMyGame()
    refetchComp()
    refetchResult()
  }
  const { mutate: mutateNonce } = useMutation({
    mutationFn: () => getNonce(),
    retry: true,
    onSuccess: (data) => {
      signAndVerifySignature(data.data)
    },
    onError: () => {
      disconnectWallet()
    }
  })
  /* const { mutate: mutateVerifySignature } = useMutation({
    mutationFn: (data) => verifySignature(data),
    onSuccess: (oRes) => {
      cookies.set('sWalletAddress', oRes?.data?.data?.sWalletAddress, { path: '/', secure: true, sameSite: 'none' })
      cookies.set('userToken', `Bearer ${oRes?.data?.data?.sToken}`, { path: '/', secure: true, sameSite: 'none' })
      cookies.remove('noraCode', { path: '/' })
      notify('success', 'Wallet connected')
      setIsSigned(true)
    },
    onError: (error) => {
      notify('error', error?.response?.data?.message || error?.message)
      disconnectWallet()
    }
  }) */
  async function signAndVerifySignature (data) {
    try {
      if (data?.data && chainId === Number(process.env.REACT_APP_NODE_ENV === 'dev'
        ? process.env.REACT_APP_TESTNET_CHAIN_ID
        : process.env.REACT_APP_MAINNET_CHAIN_ID)) {
        // const ethersProvider = new BrowserProvider(walletProvider)
        // const oData = await signInWithEthereum(data?.data, ethersProvider)
        // setIsSigned(true)
        /* if (oData?.sSignature) {
          mutateVerifySignature({
            sWalletAddress: address,
            // sSignature: oData?.sSignature,
            // sMessage: oData?.sMessage,
            // noraCode: cookies.get('noraCode')
          })
        } */
      }
    } catch (error) {
      setIsSigned(false)
      disconnectWallet()
    }
  }
  useEffect(() => {
    const nChainId =
          process.env.REACT_APP_NODE_ENV === 'dev'
            ? process.env.REACT_APP_TESTNET_CHAIN_ID
            : process.env.REACT_APP_MAINNET_CHAIN_ID

    if (chainId !== Number(nChainId)) {
      switchNetwork(Number(nChainId))
    }
    if (isConnected && chainId === Number(nChainId) && !isSigned && !cookies.get('sWalletAddress')) {
      mutateNonce()
    }
  }, [chainId])
  useEffect(() => {
    if (!isSigned) cleanTables()
    if (isSigned) {
      setLoading({ ...loading, status: true })
      setTimeout(() => {
        setLoading({ ...loading, status: false })
        const userToken = getUserToken()
        if (userToken) {
          refetchTickets()
        }
      }, 1000)
    }
  }, [isSigned])
  return (
    <>
      {loading.status ? <Loading addClass={'active'} /> : ''}
      <><Header activeTab={1} onLocalStorageChange={handleLocalStorageChange} />
        <div className='statics-tabs-sec my-game-sec'>
          <Sidebar sideActiveTab={5} />
          {/* <Sidebar sideActiveTab={4}/> */}
          {!(isConnected && isSigned)
            ? <div className='statics-main-sec-holder'>
              <div className="notConnectedBG">
                <img className='red' src={redballimg} alt="Ball" />
                <img className='blue' src={blueballimg} alt="Ball" />
                <div>
                  <div className='message'>
                    <h3>{t('global.infoConnect')}</h3>
                    <button className='connect' onClick={() => connectDisconnectWallet()}> <img src={bnb} alt='BNB' /> {t('global.connectWallet')}</button>
                  </div>
                </div>
              </div>
            </div>
            : <div className='statics-main-sec my-games-main-sec'>
          <img src={blueballimg} className='blueballimg' />
          <div className='text-center'>
            <h1>{t('views.myGame.myGame.game')}</h1>
          </div>
          <div className='stacking-tabs'>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '1'
                  })}
                  onClick={() => { toggle('1') }}
                >
                  {t('views.myGame.myGame.gameTickets')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '2'
                  })}
                  onClick={() => { toggle('2') }}
                >
                  {t('views.myGame.myGame.noriCompetition')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active:
                      currentActiveTab === '3'
                  })}
                  onClick={() => { toggle('3') }}
                >
                  {t('views.myGame.myGame.gameResult')}
                </NavLink>
              </NavItem>

            </Nav>
          </div>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <div className='p-relative'>
                <img src={redballimg} className='redballimg' />
                <div className='statics-sec my-games-sec'>
                  <div className='statics-header mb-30 mygames-header'>
                    <h4 className='common-title'>{t('views.myGame.myGame.tickets')}</h4>
                    {mgSelectedRows?.selectedCount > 0
                      ? <div>
                        <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.myGame.myGame.delete')}</span></a>
                      </div>
                      : ''
                    }
                  </div>
                  <div className='common-table-sec my-games-table'>
                    <DataTable
                      columns={columns}
                      data={rows}
                      pagination
                      paginationPerPage={9}
                      paginationComponentOptions={paginationComponentOptions}
                      onChangeRowsPerPage={() => handlePageChange(1)}
                      selectableRows
                      onSelectedRowsChange={(e) => handleMgChange(e)}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className='p-relative'>
                <img src={redballimg} className='redballimg' />
                <div className='statics-sec my-games-sec'>
                  <div className='statics-header mb-30 mygames-header'>
                    <h4 className='common-title'>{t('views.myGame.myGame.noriCompetition')}</h4>
                    {compSelectedRows?.selectedCount > 0
                      ? <div>
                        <a className='common-white-btn small-btn delete-ticket-btn mob-delet-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.myGame.myGame.delete')}</span></a>
                      </div>
                      : ''
                    }
                  </div>
                  <div className='common-table-sec my-games-table'>
                    <DataTable
                      columns={noriColumns}
                      data={noriRows}
                      pagination
                      paginationPerPage={9}
                      paginationComponentOptions={paginationComponentOptions}
                      onChangeRowsPerPage={() => handlePageChange(2)}
                      selectableRows
                      onSelectedRowsChange={(e) => handleCompChange(e)}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className='p-relative'>
                <img src={redballimg} className='redballimg' />
                <div className='statics-sec my-games-sec'>
                  <div className='statics-header mb-30 d-flex justify-content-between mygames-header'>
                    <h4 className='common-title'>{t('views.myGame.myGame.results')}</h4>
                    <div>
                      {resSelectedRows?.selectedCount > 0
                        ? <a className='common-white-btn small-btn delete-ticket-btn' onClick={() => handleClearRows(1, false)}><span>{t('views.myGame.myGame.delete')}</span></a>
                        : ''
                      }
                      {/* <a className='common-blue-btn small-btn' onClick={() => handleClearRows(2, false)}><span>{t('views.myGame.myGame.claimAll')}</span></a> */}
                      <button className='common-blue-btn small-btn' onClick={() => handleClaimAll()} disabled={isDisabledClaim} ><span>{t('views.myGame.myGame.claimAll')}</span></button>
                    </div>
                  </div>
                  <div className='common-table-sec my-games-table'>
                    <DataTable
                      columns={gameResColumns}
                      data={gameResRows}
                      pagination
                      paginationPerPage={9}
                      paginationComponentOptions={paginationComponentOptions}
                      onChangeRowsPerPage={() => handlePageChange(3)}
                      selectableRows
                      onSelectedRowsChange={(e) => handleResChange(e)}
                      selectableRowDisabled={rowDisabledCriteria}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>}
        </div>
        <Footer isVisibleBall={false} />
        <Modal toggle={deleteToggle} className='settings-popup' isOpen={deleteModal}>
          <ModalBody>
            <div className='common-modal-sec'>
              <button onClick={deleteToggle} className='common-close-btn'><img src={closeicon} /></button>
              <h3>{isClaim ? 'Success' : 'Alert'}</h3>
              {isClaim
                ? <p>{t('views.myGame.myGame.allAreClaimed')}</p>
                : currentActiveTab === '1'
                  ? <p>{mgSelectedRows?.selectedRows?.length} {mgSelectedRows?.selectedRows?.length === 1 ? 'record' : 'records'} {t('views.myGame.myGame.cannotDelete')}</p>
                  : currentActiveTab === '2'
                    ? <p>{compSelectedRows?.selectedRows?.length} {compSelectedRows?.selectedRows?.length === 1 ? 'record' : 'records'} {t('views.myGame.myGame.cannotDelete')} </p>
                    : currentActiveTab === '3' ? <p>{resSelectedRows?.selectedRows?.length} {resSelectedRows?.selectedRows?.length === 1 ? 'record' : 'records'} {t('views.myGame.myGame.cannotDelete')} </p> : ''}
            </div>
          </ModalBody>
        </Modal>
        <ConfirmationModal no={popUp} toggle={popUpToggle} modal={popUpModal} heading={popUp === 1 ? 'Delete Tickets' : 'Claim Earning'} content={popUp === 1 ? t('views.myGame.myGame.deletes') : t('views.myGame.myGame.claim')} handle={handleClearRows} />
        <MyGameDetailsModal toggle={gameToggle} modal={gameModal} detailData={detailData} />
        <CompDetailsModal toggle={compToggleToggle} modal={compModal} detailData={compDetailData} compId={compId} />
        <ResultDetailsModal toggle={resToggle} modal={resModal} resId={resId} detailData={resDetailData} />
      </></>
  )
}

export default MyGames
