import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/footer'
import Sidebar from '../../components/Sidebar'
import leftarrow from '../../assets/images/arrow-left.png'
import { useTranslation } from 'react-i18next'

import '../../assets/sass/views/stacking.scss'

const WinnersDetails = () => {
  const { t } = useTranslation('global')
  const { state } = useLocation()
  const navigate = useNavigate()
  const handleBack = () => {
    if (state.from === 'comp') {
      navigate('/mygames', { state: { from: state.from, tab: '2' } })
    } else if (state.from === 'res') {
      navigate('/mygames', { state: { from: state.from, tab: '3' } })
    }
  }
  return (
    <>
      <Header activeTab={1} />
      <div className='winning-sec'>
        <Sidebar sideActiveTab={3} />
        <div className='winning-details-sec winning-details-popup'>
          <div className='nori-details-top-sec'>
            <a onClick={handleBack}><img src={leftarrow} />{t('views.winnersDetails.back')}</a>
            <p>{t('views.winnersDetails.drawsDate')}{state?.drawDate ? `${new Date(state?.drawDate).getUTCDate() < 10 ? '0' + new Date(state?.drawDate).getUTCDate() : new Date(state?.drawDate).getUTCDate()}/${(new Date(state?.drawDate).getMonth() + 1) < 10 ? '0' + (new Date(state?.drawDate).getMonth() + 1) : new Date(state?.drawDate).getMonth() + 1}/${new Date(state?.drawDate).getFullYear()}` : '02/02/2022'}</p>
          </div>
          <div className='nori-details-header-sec'>
            <div className='row'>
              <div className='col-xl-5 col-lg-4'>
                <div className='nori-details-header'>
                  <label>{t('views.winnersDetails.numberOfOption')}</label>
                  <h3>{state?.myGameResultData?.ncb || 0}</h3>
                </div>
              </div>
              <div className='col-xl-5 col-lg-4'>
                <div className='nori-details-header'>
                  <label>{t('views.winnersDetails.ratio')}</label>
                  <h3>{state?.myGameResultData?.rcb.toFixed(6) || 0}</h3>
                </div>
              </div>
              <div className='col-xl-2 col-lg-4'>
                <div className='nori-details-header'>
                  <label>{t('views.winnersDetails.bnoriScoreack')}</label>
                  <h3>{state?.myGameResultData?.nsc.toFixed(2) || 0}</h3>
                </div>
              </div>
            </div>
            <div className='nori-details-main-sec'>
              <div className='text-center'>
                <h4 className='common-title'>{t('views.winnersDetails.from')} “<span>{state?.myGameResultData?.usr || '0100x52…7bx40'}</span>” </h4>
              </div>
              <ul>
                {state?.myGameResultData?.cnf?.ops?.op1 ? <li>{t('views.winnersDetails.step1')}</li> : ''}
                {state?.myGameResultData?.cnf?.mns?.length ? <li>{t('views.winnersDetails.step2')}<ul className='nori-green-list'>{state?.myGameResultData?.cnf?.mns?.map((mandatory, i) => <li key={i}>{mandatory}</li>)}</ul></li> : ''}
                {state?.myGameResultData?.cnf?.qns?.length ? <li>{t('views.winnersDetails.step3')} <ul className='nori-red-list'>{state?.myGameResultData?.cnf?.qns?.map((exclude, i) => <li key={i}>{exclude}</li>) || '-'}</ul></li> : ''}
                {state?.myGameResultData?.cnf?.pns?.length ? <li>{t('views.winnersDetails.step4')} <ul className='nori-yellow-list'>{state?.myGameResultData?.cnf?.pns?.map((priority, i) => <li key={i}>{priority}</li>) || '-'}</ul></li> : ''}
                {state?.myGameResultData?.cnf?.ftr
                  ? <li>{t('views.winnersDetails.step5')} <ul>
                    <li><label>{t('views.winnersDetails.step51')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.nbMn || 0} - {state?.myGameResultData?.cnf?.ftr?.nbMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step52')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.evMn || 0} - {state?.myGameResultData?.cnf?.ftr?.evMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step53')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.odMn || 0} - {state?.myGameResultData?.cnf?.ftr?.odMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step54')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.evnbMn || 0} - {state?.myGameResultData?.cnf?.ftr?.evnbMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step55')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.odnbMn || 0} - {state?.myGameResultData?.cnf?.ftr?.odnbMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step56')}</label> <span>{state?.myGameResultData?.cnf?.ftr?.pnMn || 0} - {state?.myGameResultData?.cnf?.ftr?.pnMx || 6}</span> </li>
                  </ul></li>
                  : ''}
                {state?.myGameResultData?.cnf?.adftr
                  ? <li>{t('views.winnersDetails.step6')} <ul>
                    <li><label>{t('views.winnersDetails.step61')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.avMn || 3} - {state?.myGameResultData?.cnf?.adftr?.avMx || 40}</span> </li>
                    <li><label>{t('views.winnersDetails.step62')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.clMn || 5} - {state?.myGameResultData?.cnf?.adftr?.clMx || 41}</span> </li>
                    <li><label>{t('views.winnersDetails.step63')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.spnbMn || 1} - {state?.myGameResultData?.cnf?.adftr?.spnbMx || 37}</span> </li>
                    <li><label>{t('views.winnersDetails.step64')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.speqMn || 1} - {state?.myGameResultData?.cnf?.adftr?.speqMx || 5}</span> </li>
                    <li><label>{t('views.winnersDetails.step65')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.spnqMn || 1} - {state?.myGameResultData?.cnf?.adftr?.spnqMx || 5}</span> </li>
                    <li><label>{t('views.winnersDetails.step66')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.unMn || 1} - {state?.myGameResultData?.cnf?.adftr?.unMx || 6}</span> </li>
                    <li><label>{t('views.winnersDetails.step67')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.acMn || 0} - {state?.myGameResultData?.cnf?.adftr?.acMx || 10}</span> </li>
                    <li><label>{t('views.winnersDetails.step68')}</label> <span>{state?.myGameResultData?.cnf?.adftr?.tolMn || 7}</span> </li>
                  </ul></li>
                  : ''}
                {state?.myGameResultData?.cnf?.p1ns || state?.myGameResultData?.cnf?.p2nbs ? <li>{t('views.winnersDetails.step7')} <ul className='nori-normal-list nori-partition-list'>{t('views.winnersDetails.step71')}<li>{state?.myGameResultData?.cnf?.p1ns}</li> {t('views.winnersDetails.step72')}{state?.myGameResultData?.cnf?.p2nbs ? <li>{state?.myGameResultData?.cnf?.p2nbs}</li> : '-'}</ul></li> : ''}
                {state?.myGameResultData?.cnf?.bcs?.length
                  ? <li>{t('views.winnersDetails.step8')} <ul className='nori-normal-list'>{state?.myGameResultData?.cnf?.bcs?.map((prio, i) =>
                    <><label>{t('views.winnersDetails.block')} {i + 1}</label>
                      <ul>{prio?.n?.map((subPrio, j) => <li key={j}>{subPrio}</li>)}</ul></>
                  )}</ul></li>
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer isVisibleBall={false} />
    </>
  )
}

export default WinnersDetails
